import * as React from "react";
import Text, { TextVariant } from "../components/Text";

const FontTest = () => {
  return (
    <div className="container">
      <Text variant={TextVariant.h1}>Page de test de la police Poppins</Text>

      <div className="grid grid-cols-3 my-10">
        <div>
          <Text variant={TextVariant.text2} className="text-center">
            basic
          </Text>
          <p className="font-light font-poppins">light</p>
          <p className="font-normal font-poppins">normal</p>
          <p className="font-medium font-poppins">medium</p>
          <p className="font-semibold font-poppins">semibold</p>
          <p className="font-bold font-poppins">bold</p>
          <p className="font-extrabold font-poppins">extrabold</p>
        </div>
        <div>
          <Text variant={TextVariant.text2} className="text-center">
            italic
          </Text>
          <p className="italic font-light font-poppins">light</p>
          <p className="italic font-normal font-poppins">normal</p>
          <p className="italic font-medium font-poppins">medium</p>
          <p className="italic font-semibold font-poppins">semibold</p>
          <p className="italic font-bold font-poppins">bold</p>
          <p className="italic font-extrabold font-poppins">extrabold</p>
        </div>
        <div className="pl-1 bg-secondary">
          <Text variant={TextVariant.text2} className="text-center">
            colored
          </Text>
          <p className="font-light text-greyUltraLight font-poppins">light</p>
          <p className="font-normal text-greyUltraLight font-poppins">normal</p>
          <p className="font-medium text-greyUltraLight font-poppins">medium</p>
          <p className="font-semibold text-greyUltraLight font-poppins">
            semibold
          </p>
          <p className="font-bold text-greyUltraLight font-poppins">bold</p>
          <p className="font-extrabold text-greyUltraLight font-poppins">
            extrabold
          </p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-1">
        <Text variant={TextVariant.text2} className="col-span-2 text-center">
          Texte xs
        </Text>
        <div className="text-right">
          <p className="text-xs font-light font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-normal font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-medium font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-semibold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-bold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-extrabold font-poppins">
            ceci est un exemple de test
          </p>
        </div>

        <div className="pl-1 bg-backgroundD1">
          <p className="text-xs font-light text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-normal text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-medium text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-semibold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-bold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xs font-extrabold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
        </div>

        <Text variant={TextVariant.text2} className="col-span-2 text-center">
          Texte sm
        </Text>
        <div className="text-right">
          <p className="text-sm font-light font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-normal font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-medium font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-semibold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-bold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-extrabold font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <div className="pl-1 bg-backgroundD1">
          <p className="text-sm font-light text-greyUltraLight font-poppins ">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-normal text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-medium text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-semibold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-bold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-sm font-extrabold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
        </div>

        <Text variant={TextVariant.text2} className="col-span-2 text-center">
          Texte base
        </Text>
        <div className="text-right">
          <p className="text-base font-light font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-normal font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-medium font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-semibold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-bold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-extrabold font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <div className="pl-1 bg-backgroundD1">
          <p className="text-base font-light text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-normal text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-medium text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-semibold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-bold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-base font-extrabold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <Text variant={TextVariant.text2} className="col-span-2 text-center">
          Texte lg
        </Text>
        <div className="text-right">
          <p className="text-lg font-light font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-normal font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-medium font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-semibold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-bold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-extrabold font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <div className="pl-1 bg-backgroundD1">
          <p className="text-lg font-light text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-normal text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-medium text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-semibold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-bold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-lg font-extrabold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <Text variant={TextVariant.text2} className="col-span-2 text-center">
          Texte xl
        </Text>
        <div className="text-right">
          <p className="text-xl font-light font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-normal font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-medium font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-semibold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-bold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-extrabold font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <div className="pl-1 bg-backgroundD1">
          <p className="text-xl font-light text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-normal text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-medium text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-semibold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-bold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-xl font-extrabold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
        </div>

        <Text variant={TextVariant.text2} className="col-span-2 text-center">
          Texte 3xl
        </Text>
        <div className="text-right">
          <p className="text-3xl font-light font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-normal font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-medium font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-semibold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-bold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-extrabold font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <div className="pl-1 bg-backgroundD1">
          <p className="text-3xl font-light text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-normal text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-medium text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-semibold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-bold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-3xl font-extrabold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
        </div>

        <Text variant={TextVariant.text2} className="col-span-2 text-center">
          Texte 4.5xl
        </Text>
        <div className="text-right">
          <p className="text-4.5xl font-light font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-normal font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-medium font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-semibold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-bold font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-extrabold font-poppins">
            ceci est un exemple de test
          </p>
        </div>
        <div className="pl-1 bg-backgroundD1">
          <p className="text-4.5xl font-light text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-normal text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-medium text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-semibold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-bold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
          <p className="text-4.5xl font-extrabold text-greyUltraLight font-poppins">
            ceci est un exemple de test
          </p>
        </div>
      </div>

      <Text variant={TextVariant.h2} className="mt-4">
        problème de weight avec le titre et les couleurs
      </Text>
      <div className="relative">
        <Text
          variant={TextVariant.h1}
          className=" text-greyUltraLight bg-backgroundD1 font-poppins"
        >
          Un accompagnement sur mesure
        </Text>
        <Text
          variant={TextVariant.h1}
          className="absolute text-primaryDark top-2"
        >
          Un accompagnement sur mesure
        </Text>
        <Text variant={TextVariant.h1} className="absolute top-4">
          Un accompagnement sur mesure
        </Text>
      </div>
    </div>
  );
};

export default FontTest;
